import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['template', 'follow', 'sender'];

  connect() {
    if (this.hasSenderTarget) {
      this.changeSender({ target: this.senderTarget });
    }
    // this.initUploadModal();
  }

  followTargetConnected(element) {
  }

  followTargetDisconnected(element) {
  }

  senderTargetConnected(element) {
    element.addEventListener('change', this.changeSender.bind(this));
  }

  senderTargetDisconnected(element) {
    element.removeEventListener('change', this.changeSender.bind(this));
  }

  templateTargetConnected(select) {
    select.addEventListener('change', this.changeTemplate.bind(this));
  }

  templateTargetDisconnected(select) {
    select.removeEventListener('change', this.changeTemplate.bind(this));
  }

  changeSender(event) {
    const option = event.target.options[event.target.selectedIndex || 0];
    if (option) {
      Array.from(document.getElementsByClassName(`sender-value`)).forEach(element => {
        element.value = option.getAttribute('email');
      });
    }
  }

  changeTemplate(event) {
    const $section = event.target.closest('form');

    fetch(`/campaigns/templates/${event.target.value}.json`)
      .then(response => response.json())
      .then(data => {
        if ($section) {
          $section.querySelector('.subject').value = data.subject;
          $section.querySelector('.content').value = data.content;
        }
      });
  }

  removeFollowUp(event) {
    const id = event.target.dataset.id
    if (id) {
      event.target.closest(`#campaign_${id}`).remove()
    } else {
      event.target.closest(`form`).remove()
    }
  }

  initUploadModal() {
    // set the modal menu element
    const modalId = `upload-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.uploadModal = new Modal($targetEl, options, instanceOptions);
  }

  openUploadModal() {
    this.uploadModal.show()
  }

  closeUploadModal() {
    this.uploadModal.hide()
  }
}
