import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

const LIMIT_UPLOAD = 500;
export default class extends Controller {
  static targets = ['modal', "input", "dropzone", "tableHeader", "tableBody", "preview"];

  clearFilter(event) {
    Array.from(document.querySelectorAll('[data-close-filter]')).forEach(element => element.click());

    event.target.closest('form').requestSubmit();
  }

  resetTable() {
    // Clear the table body
    this.tableBodyTarget.innerHTML = "";
    this.totalRows = 0;
    this.updateLineCounter(0);
    this.inputTarget.value = '';
    this.urlTarget.value = '';
    this.previewTarget.classList.add('hidden');
  }

  connect() {
    this.initUploadModal();
  }

  dropzoneTargetConnected() {
    this.initializeDropzone();
  }

  initializeDropzone() {
    try {
      const that = this;

      this.dropzone = new Dropzone(this.dropzoneTarget, {
        url: "/", // No actual upload
        autoProcessQueue: false,
        acceptedFiles: ".csv",
        maxFiles: 1,
        previewTemplate: document.getElementById("file-preview-template").innerHTML,
        clickable: true,
        dictDefaultMessage: "",
        init: function () {
          const message = that.dropzoneTarget.querySelector(".dz-message");
          if (message) {
            message.querySelector('button').textContent = "Drag & drop a CSV file here or click to upload"
          }

          this.on("addedfile", (file) => that.handleFileAdded(file));
          this.on("removedfile", () => {
            that.resetTable(); // Reset table on file removal
          });
        }
      });
    } catch (error) {
      console.error("Error in initializeDropzone:", error);
    }
  }

  simulateProcessing(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async handleFileAdded(file) {
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      alert("Only CSV files are allowed.");
      this.dropzone.removeFile(file);
      return;
    }

    const progressBar = file.previewElement.querySelector("[data-dz-uploadprogress]");
    const progressPercent = file.previewElement.querySelector("[data-dz-uploadprogresspercent]");
    progressBar.style.width = "0%"; // Reset progress bar
    progressPercent.textContent = "0%"; // Reset progress bar

    // Read the file as text
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvContent = e.target.result;
      const rows = csvContent.trim().split("\n").slice(1).map((row) => row.split(","));
      const lineCount = rows.length;
      this.inputTarget.value = csvContent.trim().split("\n").slice(1).join("\n");

      // Update progress as each row is processed
      const totalRows = rows.length;
      for (let i = 1; i <= totalRows; i++) { // Start from 1 to skip header
        // Simulate processing delay for demonstration
        await this.simulateProcessing(10); // 10ms delay for each row

        // Update progress percentage
        const progress = ((i / totalRows) * 100);
        progressBar.style.width = `${progress}%`;
        progressPercent.textContent = `${progress.toFixed(2)}%`;
      }

      if (lineCount > LIMIT_UPLOAD) {
        this.dropzone.removeFile(file);
      } else {
        const parsedData = rows.map((row) => this.parseRow(row));
        this.renderTable(parsedData);
      }
    };

    reader.readAsText(file);
  }

  resetTable() {
    // Clear the table body
    this.tableBodyTarget.innerHTML = "";
    this.totalRows = 0;
    this.inputTarget.value = '';
    this.previewTarget.classList.add('hidden');
  }

  parseRow(row) {
    try {
      const [email = "", firstName = "", lastName = "", company = ""] = row.map((cell) => cell.trim());
      return {email, firstName, lastName, company };
    } catch (error) {
      console.error("Error in parseRow:", error);
    }
  }

  renderTable(dataRows) {
    try {
      this.totalRows = dataRows.length;

      if (dataRows.length > 0) {
        this.previewTarget.classList.remove('hidden')
      } else {
        this.previewTarget.classList.add('hidden')
      }

      this.tableBodyTarget.innerHTML = "";

      dataRows.forEach(({ firstName, lastName, company, title }) => {
        const tr = document.createElement("tr");

        tr.innerHTML = `
          <td class="p-2 border-b text-gray-600">${firstName}</td>
          <td class="p-2 border-b text-gray-600">${lastName}</td>
          <td class="p-2 border-b text-gray-600">${company}</td>
          <td class="p-2 border-b text-gray-600">${title}</td>
        `;
        this.tableBodyTarget.appendChild(tr);
      });
    } catch (error) {
      console.error("Error in renderTable:", error);
    }
  }

  initUploadModal() {
    // set the modal menu element
    const modalId = `upload-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.uploadModal = new Modal($targetEl, options, instanceOptions);
  }

  openUploadModal() {
    this.uploadModal.show()
  }

  closeUploadModal() {
    this.uploadModal.hide()
  }
}
