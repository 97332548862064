import Dropdown from "@stimulus-components/dropdown"
import List from "list.js";

export default class extends Dropdown {
  static targets = ["search", "list", "count", "group", "checkbox", "display", "result", "close", "apply"];

  // checkboxTargetConnected(checkbox) {
  //   checkbox.addEventListener("change", this.updateDropdownDisplay.bind(this))
  // }

  // checkboxTargetDisconnected(checkbox) {
  //   checkbox.removeEventListener("change", this.updateDropdownDisplay.bind(this))
  // }

  closeTargetConnected(button) {
    button.addEventListener("click", this.clear.bind(this))
  }

  closeTargetDisconnected(button) {
    button.removeEventListener("click", this.clear.bind(this))
  }

  applyTargetConnected(button) {
    button.addEventListener("click", this.updateDropdownDisplay.bind(this))
  }

  applyTargetDisconnected(button) {
    button.removeEventListener("click", this.updateDropdownDisplay.bind(this))
  }

  updateDropdownDisplay() {
    const checkboxesCheckedCount = this.checked().length

    if (checkboxesCheckedCount == 0) {
      this.displayTarget.classList.remove('inline-flex')
      this.displayTarget.classList.add('hidden')
      this.groupTarget.classList.remove('active')
    } else {
      if (checkboxesCheckedCount > 1) {
        this.resultTarget.innerHTML = `${checkboxesCheckedCount} options`;
      } else {
        this.resultTarget.innerHTML = this.checked()[0].title;
      }
      this.groupTarget.classList.add('active')
      this.displayTarget.classList.add('inline-flex')
      this.displayTarget.classList.remove('hidden')
    }
  }

  clear() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    })
    this.updateDropdownDisplay();
    this.applyTarget.click();
  }

  connect() {
    super.connect()

    try {
      // Initialize List.js on the specified list
      this.listInstance = new List(this.element, {
        valueNames: ["item"], // Specify the class names of searchable items
        listClass: this.listTarget.classList[0], // Use the first class of the list
      });

      // Bind the search event to input changes
      this.searchTarget.addEventListener("input", (event) => this.updateSearch(event));

      console.log("List.js initialized successfully");
    } catch (error) {
      console.error("Error initializing List.js:", error);
    }
  }

  updateSearch(event) {
    try {
      const searchQuery = event.target.value.trim();
      this.listInstance.search(searchQuery);
      this.updateCounter();
    } catch (error) {
      console.error("Error searching:", error);
    }
  }

  updateCounter() {
    const visibleCount = this.listInstance.visibleItems.length;
    const totalCount = this.listInstance.size();
    if (visibleCount == totalCount) {
      this.countTarget.innerHTML = `${totalCount} elements`;
    } else if (this.searchTarget && visibleCount == 0) {
      this.countTarget.innerHTML = `No results`;
    } else {
      this.countTarget.innerHTML = `${visibleCount} results`;
    }
  }

  clearSearch() {
    this.searchTarget.value = ""; // Clear input
    this.listInstance.search(); // Reset search
    this.updateCounter(); // Update counter
  }

  checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked)
  }
}
